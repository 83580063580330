@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

/* Base Styles */
body {
  font-family: 'Poppins', sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

.app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Header Styles */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #282828;
}

.header-right {
  display: flex;
  align-items: center;
  gap: 20px;
}

h1 {
  color: #1db954;
  font-size: 2.5rem;
  margin: 0;
}

h2 {
  color: #1db954;
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Login Page */
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login h1 {
  color: #1db954;
  font-size: 3rem;
  margin-bottom: 30px;
}

.login a {
  padding: 15px 30px;
  background-color: #1db954;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: 600;
  font-size: 1.1rem;
  transition: background-color 0.3s ease;
}

.login a:hover {
  background-color: #1ed760;
}

/* Profile and Navigation */
.profile-pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #1db954;
}

.disconnect-btn {
  background-color: #282828;
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.disconnect-btn:hover {
  background-color: #404040;
}

/* Recommendations Section */
.intro-text {
  font-size: 1.1rem;
  margin-bottom: 30px;
  color: #b3b3b3;
}

.create-playlist-btn {
  background-color: #1db954;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.create-playlist-btn:hover {
  background-color: #1ed760;
}

.create-playlist-btn:disabled {
  background-color: #282828;
  cursor: not-allowed;
}

/* Track List and Items */
.track-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 30px;
}

.track-item {
  position: relative;
  background-color: #181818;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.track-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

/* Track Selection */
.track-selection {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
  background: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.track-checkbox {
  width: 18px;
  height: 18px;
  accent-color: #1db954;
}

.track-checkbox-label {
  color: white;
  font-size: 0.8rem;
  cursor: pointer;
}

/* Track Image and Badge */
.track-link {
  position: relative;
  display: block;
}

.track-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.spotify-badge {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 4px;
  border-radius: 4px;
}

.spotify-icon {
  height: 20px;
  width: auto;
}

/* Track Info */
.track-info {
  padding: 15px;
}

.track-name {
  font-weight: 600;
  font-size: 1.1rem;
  margin: 0 0 5px 0;
}

.track-artist {
  color: #b3b3b3;
  margin: 0 0 15px 0;
}

.track-info a {
  color: inherit;
  text-decoration: none;
}

.track-info a:hover {
  text-decoration: underline;
  color: #1db954;
}

/* Audio Preview */
.track-preview {
  margin: 10px 0;
}

.preview-available audio {
  width: 100%;
  margin: 5px 0;
  border-radius: 20px;
}

.preview-unavailable {
  text-align: center;
  padding: 10px;
  background: rgba(29, 185, 84, 0.1);
  border-radius: 4px;
}

.preview-unavailable p {
  color: #b3b3b3;
  margin: 0 0 8px 0;
  font-size: 0.9rem;
}

.listen-on-spotify-btn {
  display: inline-block;
  background: #1db954;
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.listen-on-spotify-btn:hover {
  background: #1ed760;
  text-decoration: none;
  color: white;
}

.recommendation-reason {
  font-size: 0.9rem;
  color: #b3b3b3;
  margin-top: 15px;
  font-style: italic;
}

/* Loading and Error States */
.loading, .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #1db954;
}

.loading-spinner, .enhancing-spinner {
  width: 50px;
  height: 50px;
  border: 3px solid rgba(29, 185, 84, 0.1);
  border-radius: 50%;
  border-top-color: #1db954;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
}

.enhancing-spinner {
  width: 20px;
  height: 20px;
  border-width: 2px;
  margin-bottom: 0;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading p {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.retry-btn {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #1db954;
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1rem;
}

.retry-btn:hover {
  background-color: #1ed760;
}

/* Enhancing Notice */
.enhancing-notice {
  background: rgba(29, 185, 84, 0.1);
  border-radius: 8px;
  padding: 12px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 0.9rem;
  color: #1db954;
}

/* Footer */
.footer {
  margin-top: 40px;
  padding: 20px;
  background-color: #282828;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.footer-links a {
  color: #b3b3b3;
  text-decoration: none;
  margin: 0 10px;
}

.footer-links .divider {
  color: #b3b3b3;
}

.footer-links a:hover {
  color: #ffffff;
}

.spotify-attribution {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #b3b3b3;
}

.spotify-logo {
  height: 24px;
  width: auto;
}